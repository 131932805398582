<script setup>
const instance = getCurrentInstance();
const slideHandling = useSlideHandling();

const emits = defineEmits(['changed']);
const props = defineProps({
  items: Number,
  notResponsive: Boolean,
  slideControl: String
});

const perPage = computed(() => props.notResponsive ? 1 : slideHandling.slidesPerPage.value);
const setSlide = (slideNumber) => {
  currentSlide.value = slideNumber
}

const currentSlide = ref(1)
const loaded = ref(false);
const pageChanged = (slide) => {
  currentSlide.value = slide * perPage.value - perPage.value + 1;
  emits('changed', currentSlide.value)
}

nextTick(() => loaded.value = true);

defineExpose({
  setSlide
})
</script>

<template>
  <div class="w-full flex justify-center" v-if="props.items / perPage > 1">
    <div class="flex gap-2 items-center h-3">

      <div v-for="i in Math.ceil(props.items / perPage)"
           @click="() => pageChanged(i)"
           :class="['navigator-dot rounded-full cursor-pointer transition-all duration-200',
           {'opacity-0':!loaded},
           {'w-3 h-3 bg-azure': i ===  Math.ceil(currentSlide / perPage)},
           {'w-2 h-2 bg-gray-700': i !==  Math.ceil(currentSlide / perPage)}]"
      >
      </div>

    </div>
  </div>
</template>

<style lang="postcss" scoped>
.navigator-dot:is(:hover, :focus-visible) {
  @apply w-4 h-4;
}

.navigator-dot:is(:hover, :focus-visible) + .navigator-dot {
  @apply w-3 h-3;
}

.navigator-dot:has(+ .navigator-dot:is(:hover, :focus-visible)) {
  @apply w-3 h-3;
}

</style>
